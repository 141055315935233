<template>
  <div class="banner">
    <!-- <img src="/img/banner.png" /> -->
    <div class="contain">
      <img class="contain_logo" src="../assets/ENJO_LOGO.png" alt="">
      <img class="contain_logo2" src="../assets/EJSOFT_LOGO_20230517.png" alt="">
      <!-- EJSOFT_LOGO_20230517
      <p class="contain_title">恩兆資訊有限公司</p> -->
      <p class="contain_subTitle">與您攜手塑造未來的資訊軟體夥伴</p>
      <p class="contain_btn" @click="getPage('contact')">Contact Us</p>
    </div>
  </div>
</template>
<script>
export default {
  setup(props, { emit }) {
    const getPage =(name)=>{
      emit("pageName",name)
    }
    return {
      getPage
    }
  }
};
</script>
<style lang="scss">
// .banner {
//   background-image: url("../assets/banner.png");
// }
</style>
