<template>
  <div class="aboutBg">
    <div class="aboutMain">
      <div class="aboutMain_title">
        <span >ABOUT</span>
      </div>
      <p class="aboutMain_contain">我們是一間專注於資訊軟體解決方案的企業。我們提供多項服務，包括大數據應用分析、人工智慧應用、雲端服務應用和客製化軟體套件開發。
      我們擁有豐富的大數據分析經驗，利用最先進的工具和技術，從海量數據中提取關鍵洞察，幫助客戶做出明智的商業決策。同時，我們的專業團隊也擅長人工智慧應用開發，利用機器學習和自然語言處理技術，開發智能系統和解決方案，提升效率和自動化流程。
      我們同時提供優質的雲端服務應用，擁有可靠、可擴展和安全的雲端基礎架構和平台。無論是雲端儲存、虛擬機器還是計算資源，我們提供度身訂造的解決方案，滿足客戶的需求和預算。
      此外，我們的團隊也專注於客製化軟體套件開發，根據客戶需求設計和開發專屬的軟體解決方案。我們以客戶至上為宗旨，與客戶建立長期合作夥伴關係，持續支援他們的業務發展。</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
