<template>
  <div class="goTop" @click="goTop">^</div>
</template>
<script>
export default {
  setup(props, { emit }) {
    const goTop = () => {
      emit("goTop");
    };
    return {
      goTop,
    };
  },
};
</script>
<style lang="scss" scoped>
.goTop {
  @apply fixed
    right-4
    bottom-14
    rounded-[50%]
    bg-orange-500
    p-2
    text-white
    text-2xl
    z-[11]
    cursor-pointer
    h-10
    w-10
    font-extrabold;
}
</style>
