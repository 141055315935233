<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <Header @pageName="pageName"/>
  <div class="main">
    <!-- <router-view /> -->
    <Banner id="banner" @pageName="pageName"/>
    <About id="about"/>
    <Service id="service" :serviceAniFlag="serviceAniFlag"/>
    <Career id="career" :careerAniFlag="careerAniFlag"/>
    <Contact id="contact"/>
    <Footer />
  </div>
  <GoTop v-if="showGoTopBtn" @goTop="goTop" />
</template>
<script>
import Header from "@/components/Header";
import Banner from "@/components/Banner";
import About from "@/components/About";
import Service from "@/components/Service";
import Career from "@/components/Career";
import Contact from './components/Contact.vue';
import Footer from './components/Footer.vue';
import GoTop from './components/GoTop.vue';
import { onMounted, ref } from 'vue';

export default {
  components: {
    Header,
    Banner,
    About,
    Service,
    Career,
    Contact,
    Footer,
    GoTop,
  },
  setup() {
    const page = ref("")
    const showGoTopBtn = ref(false)
    const serviceAniFlag = ref(false)
    const careerAniFlag = ref(false)
    const pageName =(val)=>{
      page.value = val
      goPage(val)
    }
    const goPage =(val)=>{
      let Element = document.getElementById(val)
      Element.scrollIntoView({
        behavior: "smooth",
        block: "start", 
        inline: "start"	
      })
    }
    const goTop = () => {
      if (window.scrollY > 0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    onMounted(() => {
      window.addEventListener("scroll", () => {
        if (window.scrollY / document.body.offsetHeight > 0.1) {
          showGoTopBtn.value = true;
        } else {
          showGoTopBtn.value = false;
        }
        if(window.scrollY>1000){
          serviceAniFlag.value = true
        }
        if(window.scrollY>1450){
          careerAniFlag.value = true
        }
      });
    });
    return{
      page,
      goPage,
      pageName,
      showGoTopBtn,
      goTop,
      serviceAniFlag,
      careerAniFlag,
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.main {
  @apply 
  overflow-hidden;
}
</style>
