<template>
  <div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.65544208552!2d120.43940207599084!3d23.47288997886069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e942424f271a3%3A0xa6c32bb18e7454c8!2zNjAw5ZiJ576p5biC6KW_5Y2A5Z6C5qWK6LevNTA16Jmf!5e0!3m2!1szh-TW!2stw!4v1686704611093!5m2!1szh-TW!2stw" 
    width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div class="contact">
      <img src="../assets/ENJOSOFT_LOGO.png" alt="">
      <ul>
        <li>service@mail.com</li>
        <li>+886 5  3204003</li>
        <li>600 嘉義市西區垂楊路505號5樓之三</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>