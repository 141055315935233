<template>
  <div class="service">
    <div class="serviceBanner"></div>
    <div class="serviceBg">
      <div class="serviceMain">
        <div class="serviceDesc">
          <div class="serviceDesc_title">
            <span>SERVICE</span>
          </div>
          <p>我們的使命是成為您在資訊軟體解決方案領域的最佳合作夥伴。我們致力於提供卓越的技術產品和服務，以協助您實現業務目標並追求創新。無論您需要大數據應用分析、人工智慧應用、雲端服務還是客製化軟體套件開發，我們的團隊都以客戶至上的態度，致力於為您提供量身訂造的解決方案。</p>
        </div>
        <div :class="['serviceItem',{animation:serviceAniFlag}]">
          <div class="serviceItem_item">
            <p class="serviceItem_item_title">大數據的應用及分析</p>
            <p class="serviceItem_item_contain">預測市場情勢的評估，提升經營績效以及企業組織內運作模式及經營策略，並將資料分析結果即時運用在商業決策上，可迅速取得競爭力的優勢</p>
          </div>
          <div class="serviceItem_item">
            <p class="serviceItem_item_title">人工智慧決策與應用</p>
            <p class="serviceItem_item_contain">強化使用者經驗、提供視覺化的資料分析、做未來的消費預測、根據使用者的行為推薦他們真正會喜歡的商品</p>
          </div>
          <div class="serviceItem_item">
            <p class="serviceItem_item_title">雲端服務應用及發展</p>
            <p class="serviceItem_item_contain">透過網路連接獲取軟體和服務的運算模式，讓使用者可以獲得有如使用超級電腦的體驗，更快速適應所需的工作負載</p>
          </div>
          <div class="serviceItem_item">
            <p class="serviceItem_item_title">客製化軟體套件開發</p>
            <p class="serviceItem_item_contain">以豐富的客製軟體開發服務經驗，依照客戶的需求設計與開發程式，保留客戶既有作業流程，以模組化方式進行開發，降低開發時程與成本</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    serviceAniFlag: Boolean,
  }
};
</script>
