<template>
  <header>
    <div class="menu">
      <!-- <p @click="getPage('banner')">恩兆資訊有限公司</p> -->
      <img class="menu_logo" src="../assets/ENJOSOFT_w.png" alt="" @click="getPage('banner')">
      <ul v-if=" clientWidth > 1023 ">
        <li @click="getPage('about')">ABOUT</li>
        <li @click="getPage('service')">SERVICE</li>
        <li @click="getPage('career')">CAREER</li>
        <li @click="getPage('contact')">CONTACT</li>
      </ul>
      <div v-else class="mobile" @click="toggleMenu">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <ul v-if="isMenuOpen" class="mobileMenu">
      <li @click="getPage('about')">ABOUT</li>
      <li @click="getPage('service')">SERVICE</li>
      <li @click="getPage('career')">CAREER</li>
      <li @click="getPage('contact')">CONTACT</li>
    </ul>
  </header>
</template>

<script>
import { onMounted, ref } from 'vue';
export default {
  setup(props, { emit }) {
    const clientWidth = ref(0)
    const isMenuOpen = ref(false)
    const getPage =(name)=>{
      emit("pageName",name)
      isMenuOpen.value = false;
    }
    const toggleMenu = (()=>{
      isMenuOpen.value = !isMenuOpen.value
    })
    onMounted(()=>{
      clientWidth.value = document.body.clientWidth;
      window.onresize = () => {
        clientWidth.value = document.body.clientWidth;
        isMenuOpen.value = false;
      };
      document.title = "恩兆資訊有限公司"
    })
    return {
      getPage,
      clientWidth,
      isMenuOpen,
      toggleMenu,
    }
  }
};
</script>
