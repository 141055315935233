<template>
  <div class="career">
    <div class="career_title">
      <span>CAREER</span>
    </div>
    <div :class="['career_contain',{animation:careerAniFlag}]">
      <div class="career_contain_item">
        <i class="icon-codepen"></i>
        <p>Front-end Developer</p>
        <ul>
            <li>網站維護及新功能開發</li>
            <li>整合API並串接提供使用者最優質服務</li>
            <li>協助產品人員規劃流程</li>
            <li>新開發方式可行性評估及實作</li>
        </ul>
      </div>
      <div class="career_contain_item">
        <i class="icon-code"></i>
        <p>Java Developer</p>
        <ul>
            <li>協助架構及開發公司營運所需之API服務</li>
            <li>負責軟體程式撰寫與維護</li>
            <li>規劃程式技術架構及系統整合</li>
            <li>系統分析、程式設計及文件撰寫</li>
        </ul>
      </div>
      <div class="career_contain_item">
        <i class="icon-data-science"></i>
        <p>Senior Network Engineer</p>
        <ul>
            <li>虛擬主機建置、管理與維護</li>
            <li>伺服器(Windows、Linux)建置、管理維護</li>
            <li>網路設備建置、管理、維護等相關工作</li>
            <li>資安系統建置、優化</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    careerAniFlag: Boolean,
  }

}
</script>

<style>

</style>